<template>
  <div class="public-box">
    <el-form :inline="true">
      <el-form-item>
        <MyInput
          v-model="formInline.bill_offline_no"
          placeholder="输入订单编号"
        >
          <template slot="pre">订单编号:</template>
        </MyInput>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="date"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="dateChange"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-form-item>
      <!-- <el-form-item>
      <el-select v-model="formInline.bill_payee" clearable placeholder="请选择操作人">
        <el-option v-for="item in workerList" :key="item.user_id" :value="item.name" :label="item.name"></el-option>
      </el-select>
    </el-form-item> -->
      <el-form-item>
        <MyButton title="搜索" class="offset" @click="search">
          <template slot="preImage">
            <img src="../../../assets/img/icon/search.png" alt />
          </template>
        </MyButton>
      </el-form-item>
      <el-form-item label>
        <MyButton type="button" @click="history">
          <template slot="preImage">
            <img src="../../../assets/img/his.png" alt="" />
          </template>
          <span>历史核销记录</span>
        </MyButton>
      </el-form-item>
      <el-form-item>
        <MyButton
          :accessId="68135"
          @click="
            $exported(
              '/property/bill_offline/exportC ',
              formInline,
              '现金账单列表.xlsx'
            )
          "
        >
          <span>导出</span>
          <template slot="preImage">
            <img src="../../../unit/img/down.png" alt="" />
          </template>
        </MyButton>
      </el-form-item>
    </el-form>

    <div class="public-table">
      <el-table
        :data="tableData"
        :header-cell-style="{
          'text-align': 'center',
          background: 'rgb(245, 245, 245)',
        }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column label="凭证号" prop="voucher_number"></el-table-column>
        <el-table-column label="收费时间" prop="pay_time"></el-table-column>
        <el-table-column
          label="实收金额"
          prop="key_pay_price"
        ></el-table-column>
        <!-- <el-table-column label="收费人" prop="bill_payee"></el-table-column> -->
        <el-table-column label="操作">
          <template slot-scope="scope">
            <pop
              tips="确认核销"
              :accessId="64881"
              @myclick="details(scope.row)"
            >
              <img class="icon" src="../../../assets/img/icon/quer.png" alt />
            </pop>
          </template>
        </el-table-column>
        <div></div>
      </el-table>
      <div class="summation">
        <div class="list">汇总核销</div>
        <div class="list">{{ (yesterdayData && yesterdayData.date) || 0 }}</div>
        <div class="list">
          {{ (yesterdayData && yesterdayData.pay_price) || 0 }}
        </div>
        <!-- <div>/</div> -->
        <div>
          <pop
            tips="确认核销"
            :accessId="64881"
            @myclick="allCancelAfter"
            v-if="yesterdayData && yesterdayData.pay_price > 0"
          >
            <img class="icon" src="../../../assets/img/icon/quer.png" alt />
          </pop>
        </div>
      </div>
      <div class="apiPage">
        <el-pagination
          next-text="下一页"
          prev-text="上一页"
          layout="total, prev, pager, next"
          :total="total"
          :page-size="formInline.pageSize"
          :current-page="formInline.page"
          @current-change="getList"
        ></el-pagination>
      </div>
    </div>
    <el-dialog
      title="异常核对"
      :visible.sync="dialogVisible"
      width="880px"
      @close="handleClose"
      :close-on-click-modal="false"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="到账总金额" prop="actual_price">
          <el-input
            v-model="ruleForm.actual_price"
            class="public-input"
            placeholder="请输入到账总金额"
          ></el-input>
        </el-form-item>
        <el-form-item label="异常原因">
          <el-input
            v-model="ruleForm.abnormal_reason"
            class="public-input"
            placeholder="请输入异常原因"
          ></el-input>
        </el-form-item>
        <el-form-item label="处理方案">
          <el-input
            v-model="ruleForm.deal_plan"
            class="public-input"
            placeholder="请输入处理方案"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <MyButton title="取消" @click="dialogVisible = false"></MyButton>
        <MyButton
          title="确认"
          type="primary"
          class="offset"
          @click="affirm"
        ></MyButton>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "cashbill",
  data() {
    return {
      formInline: {
        bill_offline_no: "",
        pageSize: 10,
        pageNum: 1,
        payBeginTime: "",
        payEndTime: "",
        extract_status: 10,
        pay_type: 30,
        bill_status: 10,
        bill_payee: "",
      },
      total: 0,
      date: [],
      tableData: [],
      dialogVisible: false,
      rules: {
        actual_price: [
          { required: true, message: "请输入到账总金额", trigger: "blur" },
        ],
      },
      ruleForm: {
        bill_offline_ids: [],
        pay_type: 30,
        payBeginTime: "",
        payEndTime: "",
        abnormal_reason: "",
        deal_plan: "",
        actual_price: "",
      },
      workerList: [],
      yesterday: {
        payBeginTime: "",
        payEndTime: "",
      },
      yesterdayData: null,
      isAll: false,
    };
  },
  created() {
    let day1 = new Date();
    day1.setTime(day1.getTime() - 24 * 60 * 60 * 1000);
    let terday = this.$common.formatDateTime(day1);
    this.yesterday.payBeginTime = terday;
    this.yesterday.payEndTime = terday;
    this.formInline.payBeginTime = terday;
    this.formInline.payEndTime = terday;
    this.getList();
    this.getWorkerList();
    this.getListTotal();
  },
  methods: {
    allCancelAfter() {
      this.isAll = true;
      this.dialogVisible = true;
    },
    search() {
      this.getList();
      this.getListTotal();
    },
    affirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.isAll) {
            if (this.formInline.payBeginTime) {
              this.ruleForm.payBeginTime = this.formInline.payBeginTime;
              this.ruleForm.payEndTime = this.formInline.payEndTime;
            } else {
              this.ruleForm.payBeginTime = this.yesterday.payBeginTime;
              this.ruleForm.payEndTime = this.yesterday.payEndTime;
            }
          } else {
            this.ruleForm.payBeginTime = "";
            this.ruleForm.payEndTime = "";
          }
          this.$request
            .HttpPost("/bill_offline/setExtract", this.ruleForm)
            .then((res) => {
              this.$common.notifySuccess(res.msg);
              this.dialogVisible = false;
              this.search();
            });
        }
      });
    },
    getListTotal() {
      this.yesterday.pay_type = 30;
      console.log(this.yesterday);
      this.$request
        .HttpGet("/bill_offline/listExtractAll", this.yesterday)
        .then((res) => {
          this.yesterdayData = res.data;
          this.date = res.data.date.split("~");
          console.log(this.date);
        });
    },
    history() {
      this.$router.push({
        path: "/finance/accountcheck/cashHistory",
        query: {
          type: 20,
        },
      });
    },
    getWorkerList() {
      this.$request
        .HttpGet("/worker/list", {
          pageNum: 1,
          pageSize: 10000,
        })
        .then((res) => {
          this.workerList = res.data.list;
        });
    },
    getList(e = 1) {
      this.formInline.pageNum = e;
      this.$request
        .HttpGet("/bill_offline/list", this.formInline)
        .then((res) => {
          if (res.data && res.data.list) {
            this.total = res.data.total;
            this.tableData = res.data.list;
          } else {
            this.total = 0;
            this.tableData = [];
          }
        });
    },
    dateChange(date) {
      this.formInline.payBeginTime = date ? date[0] : "";
      this.formInline.payEndTime = date ? date[1] : "";
      if (date) {
        this.yesterday.payBeginTime = date[0];
        this.yesterday.payEndTime = date[1];
      }
    },
    handleClose() {
      this.ruleForm = {
        bill_offline_ids: [],
        pay_type: 30,
        payBeginTime: "",
        payEndTime: "",
        abnormal_reason: "",
        deal_plan: "",
        actual_price: "",
      };
    },
    details(row) {
      this.isAll = false;
      this.ruleForm.bill_offline_ids = [row.bill_offline_id];
      this.dialogVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.autoFlex {
  display: flex;
  justify-content: space-between;
  .right {
    display: flex;
    align-items: center;
    .totalAmout {
      margin-right: 15px;
    }
  }
}

/deep/ .el-table .el-table__cell {
  text-align: center;
}
.summation {
  display: flex;
  width: 100%;
  height: 48px;
  align-items: center;
  border-bottom: 1px solid #ebeef5;
  justify-content: space-between;
  div {
    text-align: center;
    flex: 1;
  }
}
</style>
